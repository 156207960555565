import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import Page from '../../containers/DraggerGame/Result'
import Seo from '../../containers/Seo'
import bonnechance from '../../games/bonne-chance.json'

const Game = (props) => {
  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`)
  return (
    <>
      <Seo.BonneChance />
      <Page
        {...props}
        data={data}
        d={bonnechance}
      />
    </>
  )
}

export default Game
